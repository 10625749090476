.container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex: 1;
  padding: 0 18px 42px;
}

.content {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.action {
  margin-top: 24px;
}

a.inlineLink {
  color: rgba(var(--clrSecondary));
  font-style: italic;
  text-decoration: none;
  display: inline-block;
}
