.container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex: 1;
  padding: 0 18px 30px;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 30px 0;
}

.title {
  margin-top: 24px;
}

.description {
  margin-top: 12px;
}

.action {
  margin-top: 24px;
}
