.container {
  display: flex;
  position: relative;
  overflow: hidden;
  flex: 1;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ratio16_9 {
  padding-top: 56.25%;
}

.ratio4_3 {
  padding-top: 75%;
}

.ratio1_1 {
  padding-top: 100%;
}
