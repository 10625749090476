.container {
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  outline: none;
  margin: 0;
  padding: 0;
  transition: opacity 0.3s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.container:active {
  opacity: 0.6;
}

.container:hover {
  opacity: 0.6;
}
