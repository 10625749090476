.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 50px 18px 24px 18px;
  overflow: auto;
}

.content {
  flex: 1;
  overflow: auto;
  box-sizing: border-box;
}

.reservationTypeWrapper {
  display: flex;
  flex-direction: column;
  padding-top: 18px;
  padding-bottom: 6px;
  border-top: solid 1px rgba(var(--clrNtrlMin));
  border-bottom: solid 1px rgba(var(--clrNtrlMin));
}

.reservationType {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 12px 0 0;
}

.reservationButton {
  margin: 0 6px 12px 6px;
}

.reservationType {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.helpText {
  padding-top: 18px;
  margin-bottom: 24px;
  border-top: solid 1px rgba(var(--clrNtrlMin));
}

.button {
  height: 36px;
  border-radius: 18px;
  padding: 0 18px;
  margin-left: 12px;
}

.actions {
  padding-top: 18px;
  flex-shrink: 0;
}

.month::first-letter {
  text-transform: uppercase;
}

.terms {
  align-items: flex-start;
  flex-direction: column;
}

.termsDescription {
  margin-top: 6px;
}

@media (max-width: 360px) {
  .container {
    padding-top: 48px;
  }
}
