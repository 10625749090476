.container {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 8px;
}

.container span {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(var(--clrWhite));
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.container span:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.container span:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.container span:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.container span:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

/* VARIANTS */
.container.white span {
  background: rgba(var(--clrWhite));
}

.container.primary span {
  background: rgba(var(--clrPrimary));
}

.container.secondary span {
  background: rgba(var(--clrSecondary));
}

.container.max span {
  background: rgba(var(--clrNtrlMax));
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
