.container {
  padding: 12px;
  box-sizing: border-box;
  min-height: 60px;
  box-shadow: 0 1px 5px rgba(var(--clrBlack), var(--alpha));
  border-radius: 3px;
  position: relative;
  transition: all 0.3s ease;
  background-color: rgba(var(--clrWhite));
}

button.container:active {
  transform: scale(0.95);
}

button.container {
  outline: none;
  border: none;
}
