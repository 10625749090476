.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 18px 42px;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 30px 0;
}

.title {
  margin-top: 24px;
}

.description {
  margin-top: 12px;
}

.resendWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.resendButton {
  height: auto;
  width: auto;
}
