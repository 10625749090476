.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.s {
  flex: 1;
  max-width: 200px;
}

.m {
  max-width: 300px;
  flex: 1;
}

.l {
  max-width: 400px;
}

.ayuntamiento {
  margin-bottom: 24px;
}

.ayuntamiento.s {
  max-width: 100px;
}

.ayuntamiento.m {
  max-width: 160px;
}

.ayuntamiento.l {
  max-width: 200px;
}
