.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  box-sizing: border-box;
}

.title {
  margin-bottom: 6px;
}

.description {
  margin-bottom: 18px;
}

.content {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
}

.error {
  margin-top: 12px;
}

.section {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
}

.section + .section {
  margin-top: 24px;
}

.sectionTitle {
  margin-bottom: 6px;
}
