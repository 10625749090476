.container {
  padding: 80px 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  margin-bottom: 12px;
}

.cta {
  margin-top: 30px;
  width: 220px;
}

.p {
  margin-top: 12px;
}

.button {
  margin-top: 24px;
  position: relative;
  padding: 0 24px;
  height: 48px;
  border-radius: 24px;
  background-color: transparent;
  border: solid 1px transparent;
  font-size: 16px;
  color: rgba(var(--clrWhite));
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  /* fix a strange behavior with transparency hover that increase the grid height */
  transform: translateZ(0);
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 16px;
  border-color: rgb(var(--clrNtrlMax));
  color: rgb(var(--clrNtrlMax));
  display: inline-flex;
  text-decoration: none;
  box-sizing: border-box;
}

.button:active,
.button:hover {
  opacity: 0.6;
}
