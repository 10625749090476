.carousel {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 118px;
}

.carouselItem {
  padding-right: 18px;
  box-sizing: border-box;
  overflow: visible;
  width: 100%;
  height: 118px;
}

.carousel :global(.carousel.carousel-slider) {
  height: 100%;
  overflow: visible;
}

.carousel :global(.carousel .slider-wrapper) {
  overflow: visible;
}

.carousel :global(.carousel .slide) {
  background-color: transparent;
}

.carousel :global(.carousel .control-dots) {
  position: relative;
  padding-right: 18px;
}

.carousel :global(.carousel .control-dots .dot) {
  height: 12px;
  width: 12px;
  border-radius: 6px;
  box-sizing: border-box;
  border: solid 1px rgba(var(--clrSecondary));
  flex-shrink: 0;
  transition: background-color 0.3s ease;
  box-shadow: none;
  opacity: 1;
  margin: 0 9px;
}

.carousel :global(.carousel .control-dots .dot.selected) {
  background-color: rgba(var(--clrSecondary));
}
