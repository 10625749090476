.container {
  width: 100%;
  height: 86px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.decorator {
  display: block;
  border-radius: 50%;
  background-color: rgba(var(--clrWhite));
  flex-shrink: 0;
}
