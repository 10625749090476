.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 50px 18px 24px 18px;
  overflow: auto;
}

.title {
  margin-bottom: 6px;
}

.content {
  flex: 1;
  overflow: auto;
  box-sizing: border-box;
}

.actions {
  padding-top: 18px;
  flex-shrink: 0;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
