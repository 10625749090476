.container {
  padding: 80px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.content {
  flex: 1;
  padding: 0 54px;
  box-sizing: border-box;
}

.videoWrapper {
  flex: 1;
  min-width: 300px;
  align-self: flex-start;
}

.video {
  object-fit: cover;
  width: 100%;
}

.image {
  flex: 1;
  object-fit: cover;
  min-width: 300px;
  align-self: flex-start;
}

.image > img {
  width: 100%;
}

.block {
  margin-top: 24px;
}

.p {
  margin-bottom: 24px;
}

.title {
  margin-top: 12px;
  margin-bottom: 6px;
}

.button {
  margin-top: 24px;
  position: relative;
  padding: 0 24px;
  height: 48px;
  border-radius: 24px;
  background-color: transparent;
  border: solid 1px transparent;
  font-size: 16px;
  color: rgba(var(--clrWhite));
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  /* fix a strange behavior with transparency hover that increase the grid height */
  transform: translateZ(0);
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 16px;
  border-color: rgb(var(--clrNtrlMax));
  color: rgb(var(--clrNtrlMax));
  display: inline-flex;
  text-decoration: none;
  box-sizing: border-box;
}

.button:active,
.button:hover {
  opacity: 0.6;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

@media (max-width: 768px) {
  .content {
    padding: 0 24px;
    width: 100%;
  }

  .button {
    white-space: pre-wrap;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .video {
    margin-top: 24px;
  }

  .actions {
    justify-content: center;
  }
}
