.container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex: 1;
  padding: 0 18px 30px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 24px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.action {
  margin-top: 24px;
}
