.disabled {
  opacity: var(--alpha);
  fill: rgba(var(--clrNtrlLight));
}

.selected {
  fill: rgba(var(--clrPrimary));
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
