.button {
  height: 36px;
  border-radius: 18px;
  padding: 0 18px;
  margin-left: 12px;
  max-width: 160px;
}

.input {
  opacity: 0;
  position: absolute;
}
