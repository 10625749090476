.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.indicator {
  height: 12px;
  width: 12px;
  border-radius: 6px;
  box-sizing: border-box;
  border: solid 1px rgba(var(--clrSecondary));
  flex-shrink: 0;
  transition: background-color 0.3s ease;
}

.indicator + .indicator {
  margin-left: 18px;
}

.indicator.active {
  background-color: rgba(var(--clrSecondary));
}
