.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 18px 42px;
  box-sizing: border-box;
  height: 100%;
}

.content {
  flex: 1;
  overflow: auto;
}

.header {
  margin-bottom: 12px;
}

.block {
  margin-bottom: 24px;
}

.actions {
  padding-top: 18px;
  flex-shrink: 0;
  display: flex;
}

.action {
  flex: 1;
  margin: 0 6px;
}

.action:first-of-type {
  margin-left: 0;
}

.action:last-of-type {
  margin-right: 0;
}
