.container {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius: 18px;
  border: solid 1px rgba(var(--clrSecondary));
}

.increment {
  width: 72px;
  height: 36px;
  margin-right: 6px;
}

.decrement {
  height: 36px;
  width: 36px;
}

.currentValue {
  width: 36px;
}
