.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 42px;
  flex-direction: column;
  padding: 0 18px 42px;
  box-sizing: border-box;
  background: #fff;
}

.logoWrapper {
  align-self: stretch;
  flex: 0;
  display: flex;
  align-items: center;
}
