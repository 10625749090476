.container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex: 1;
  padding: 0 18px 30px;
}

.content {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.content form {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.header .backButton {
  margin-right: 12px;
}

.action {
  margin-top: 24px;
}

.inlineLink {
  color: rgba(var(--clrSecondary));
  font-style: italic;
  text-decoration: none;
}

.forgotPasswordLink {
  align-self: flex-end;
  margin: 12px 0 0;
}
