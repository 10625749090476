.container {
  width: 100%;
  height: 75px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
}

.content {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 18px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.actionIconWrapper {
  height: 24px;
  margin-right: 12px;
}

.rightActionWrapper {
  height: 24px;
  margin-left: 12px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.black {
  fill: rgba(var(--clrBlack));
}

.white {
  fill: rgba(var(--clrWhite));
}

.max {
  fill: rgb(var(--clrNtrlMax));
}

.dark {
  fill: rgb(var(--clrNtrlDark));
}

.light {
  fill: rgb(var(--clrNtrlLight));
}

.min {
  fill: rgb(var(--clrNtrlMin));
}

.primary {
  fill: rgba(var(--clrPrimary));
}

.secondary {
  fill: rgba(var(--clrSecondary));
}

.info {
  fill: rgba(var(--clrInfo));
}

.positive {
  fill: rgba(var(--clrPositive));
}

.negative {
  fill: rgba(var(--clrNegative));
}

.warning {
  fill: rgba(var(--clrWarning));
}

.panda {
  fill: rgba(var(--clrPanda));
}

.pandaDark {
  fill: rgba(var(--clrPandaDark));
}

.flamingo {
  fill: rgba(var(--clrFlamingo));
}

.cocodrile {
  fill: rgba(var(--clrCocodrile));
}

.pig {
  fill: rgba(var(--clrPig));
}

.koala {
  fill: rgba(var(--clrKoala));
}

.parrot {
  fill: rgba(var(--clrParrot));
}

.parrotLight {
  fill: rgba(var(--clrParrotLight));
}

.octupus {
  fill: rgba(var(--clrOctopus));
}
