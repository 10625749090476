.container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex: 1;
  padding: 0 18px;
  box-sizing: border-box;
  height: 100%;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 42px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.header .backButton {
  margin-right: 12px;
}

.block {
  margin-top: 24px;
}

.logoutWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}
