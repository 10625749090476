.button {
  position: relative;
  padding: 0 24px;
  height: 48px;
  border-radius: 24px;
  background-color: transparent;
  border: solid 1px transparent;
  font-size: 16px;
  color: rgba(var(--clrWhite));
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  /* fix a strange behavior with transparency hover that increase the grid height */
  transform: translateZ(0);
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.autoSize {
  width: initial;
}

.label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 16px;
}

/* Variant */
.black.inverted {
  border-color: rgba(var(--clrBlack));
}

.black {
  background-color: rgba(var(--clrBlack));
}

.white.inverted {
  border-color: rgba(var(--clrWhite));
}

.white {
  background-color: rgba(var(--clrWhite));
}

.max.inverted {
  border-color: rgb(var(--clrNtrlMax));
}

.max {
  background-color: rgb(var(--clrNtrlMax));
}

.dark.inverted {
  border-color: rgb(var(--clrNtrlDark));
}

.dark {
  background-color: rgb(var(--clrNtrlDark));
}

.light.inverted {
  border-color: rgb(var(--clrNtrlLight));
}

.light {
  background-color: rgb(var(--clrNtrlLight));
}

.min.inverted {
  border-color: rgb(var(--clrNtrlMin));
}

.min {
  background-color: rgb(var(--clrNtrlMin));
}

.primary.inverted {
  border-color: rgba(var(--clrPrimary));
}

.primary {
  background-color: rgba(var(--clrPrimary));
}

.primaryLight.inverted {
  border-color: rgba(var(--clrPrimaryLt));
}

.primaryLight {
  background-color: rgba(var(--clrPrimaryLt));
}

.secondary.inverted {
  border-color: rgba(var(--clrSecondary));
}

.secondary {
  background-color: rgba(var(--clrSecondary));
}

.secondaryLight.inverted {
  border-color: rgba(var(--clrSecondaryLt));
}

.secondaryLight {
  background-color: rgba(var(--clrSecondaryLt));
}

.info.inverted {
  border-color: rgba(var(--clrInfo));
}

.info {
  background-color: rgba(var(--clrInfo));
}

.positive.inverted {
  border-color: rgba(var(--clrPositive));
}

.positive {
  background-color: rgba(var(--clrPositive));
}

.negative.inverted {
  border-color: rgba(var(--clrNegative));
}

.negative {
  background-color: rgba(var(--clrNegative));
}

.warning.inverted {
  border-color: rgba(var(--clrWarning));
}

.warning {
  background-color: rgba(var(--clrWarning));
}

.panda.inverted {
  border-color: rgba(var(--clrPanda));
}

.panda {
  background-color: rgba(var(--clrPanda));
}

.pandaDark.inverted {
  border-color: rgba(var(--clrPandaDark));
}

.pandaDark {
  background-color: rgba(var(--clrPandaDark));
}

.flamingo.inverted {
  border-color: rgba(var(--clrFlamingo));
}

.flamingo {
  background-color: rgba(var(--clrFlamingo));
}

.cocodrile.inverted {
  border-color: rgba(var(--clrCocodrile));
}

.cocodrile {
  background-color: rgba(var(--clrCocodrile));
}

.pig.inverted {
  border-color: rgba(var(--clrPig));
}

.pig {
  background-color: rgba(var(--clrPig));
}

.koala.inverted {
  border-color: rgba(var(--clrKoala));
}

.koala {
  background-color: rgba(var(--clrKoala));
}

.parrot.inverted {
  border-color: rgba(var(--clrParrot));
}

.parrot {
  background-color: rgba(var(--clrParrot));
}

.parrotLight.inverted {
  border-color: rgba(var(--clrParrotLight));
}

.parrotLight {
  background-color: rgba(var(--clrParrotLight));
}

.octupus.inverted {
  border-color: rgba(var(--clrOctopus));
}

.octupus {
  background-color: rgba(var(--clrOctopus));
}

/* should be placed at the end to override de
background color of positive, negative... types */
.inverted {
  background: transparent;
  color: rgba(var(--clrNtrlMax));
}

.button:active,
.button:hover {
  opacity: 0.6;
}

.button[disabled],
.button[disabled].inverted {
  opacity: var(--alpha);
  cursor: default;
}

.button[disabled]:active,
.button[disabled]:hover {
  opacity: var(--alpha);
}
