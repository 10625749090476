.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  box-sizing: border-box;
  overflow: auto;
}

.title {
  margin-bottom: 6px;
}

.description {
  margin-bottom: 24px;
}

.content {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.cheers {
  margin-top: 24px;
}

.error {
  margin-top: 24px;
}

.tips {
  margin: 0;
  margin-bottom: 60px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 0;
}

.tips li {
  display: flex;
  gap: 6px;
}
