.container {
  padding: 24px;
  flex-direction: column;
  border-radius: 24px;
  max-width: 360px;
  position: relative;
  background-color: rgba(var(--clrCocodrile));
}

.container:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  background: url('./assets/decoration.svg') no-repeat bottom left;
  opacity: var(--alpha);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  width: 100%;
}

.label {
  letter-spacing: 1.4px;
  opacity: var(--alpha);
}

.extraInfo {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 12px;
}
