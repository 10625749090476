.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding-top: 25px;
}

.mainInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 18px 30px;
  flex-shrink: 0;
}

.name {
  margin-top: 18px;
}

.infoWrapper {
  flex: 1;
  background-color: rgba(var(--clrNtrlMin));
}

.actions {
  padding: 24px 18px;
  height: 96px;
  box-sizing: border-box;
}
