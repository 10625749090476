.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title {
  margin-top: 12px;
  margin-bottom: 18px;
}

.action {
  width: auto;
  height: 32px;
}
