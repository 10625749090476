.actions {
  padding: 42px 18px 30px;
  transition: all 0.3 ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actions.multipleActions {
  padding: 42px 0 30px;
}

.actions.multipleActions .action {
  width: auto;
  min-width: 130px;
}

.actions.multipleActions .action:first-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  opacity: 0.6;
}

.actions.multipleActions .action:last-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.carousel {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.carouselItem {
  height: 100% !important;
  padding: 0;
  box-sizing: border-box;
  display: flex;
}

.carouselIndicators {
  margin-top: 12px;
}
