.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 50px 18px 24px 18px;
  overflow: auto;
}

.title {
  margin-bottom: 6px;
}

.vouchers {
  flex: 1;
  overflow: auto;
  box-sizing: border-box;
  margin: 18px -18px;
  padding: 6px 18px;
}

.actions {
  padding-top: 12px;
  flex-shrink: 0;
}

.loadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
