.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 50px 18px 24px 18px;
  overflow: auto;
}

.title {
  margin-bottom: 6px;
}

.helpText {
  margin-top: 12px;
}

.card {
  margin: 18px 0;
  flex-shrink: 0;
}

.actions {
  margin-top: 30px;
  flex: 1;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}

.actionsTitle {
  margin-bottom: 12px;
}

.row + .row {
  border-top: solid 1px rgba(var(--clrNtrlMax), var(--alphaMin));
}

.block {
  margin-top: 24px;
}

@media screen and (max-width: 320px) {
  .container {
    padding-top: 40px;
  }
}
