.container {
  width: 100%;
  max-width: 320px;
  border: solid 1px rgba(var(--clrNtrlDark), var(--alpha));
  position: relative;
  box-sizing: border-box;
  border-radius: 12px;
}

.icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgba(var(--clrNtrlMin));
  border-radius: 12px;
}

.floatIcon {
  width: 42px;
  height: 42px;
  position: absolute;
  bottom: -10px;
  right: -10px;
  border-radius: 50%;
  background-color: rgba(var(--clrSecondary));
}

.card {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

div.aspectRatio {
  overflow: visible;
}

@media (max-width: 330px) {
  .container {
    max-width: 100%;
  }
}
