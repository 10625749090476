.container {
  min-width: 72px;
  height: 36px;
  border-radius: 18px;
  border: solid 1px rgba(var(--clrSecondary));
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  flex-shrink: 0;
}

.container + .container {
  margin-left: 6px;
}

.active {
  background-color: rgba(var(--clrSecondary));
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}
