.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 50px 18px 24px 18px;
  overflow: auto;
}

.content {
  flex: 1;
  overflow: auto;
  box-sizing: border-box;
}

.hoursOptions {
  flex-direction: column;
}

.hours {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 12px 12px;
  margin-top: 24px;
  width: 100%;
}

.hours .hour {
  flex: 1;
  margin-left: 0;
}

.infoWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.infoWrapper .chart {
  width: 60px;
  margin-right: 12px;
  flex-shrink: 0;
  flex-grow: 0;
}

.helpText {
  font-style: italic;
  margin-top: 6px;
}

.button {
  height: 36px;
  border-radius: 18px;
  padding: 0 18px;
  margin-left: 12px;
}

.actions {
  padding-top: 18px;
  flex-shrink: 0;
}

@media (max-width: 360px) {
  .container {
    padding-top: 48px;
  }
}
