.container {
  width: 100%;
  flex-shrink: 0;
  padding: 14px 0;
  outline: none;
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: none;
}

.container + .container {
  border-top: solid 1px rgba(var(--clrNtrlMax), var(--alphaMin));
}

.container:first-of-type {
  border-top: solid 1px rgba(var(--clrNtrlMax), var(--alphaMin));
}

.container:last-of-type {
  border-bottom: solid 1px rgba(var(--clrNtrlMax), var(--alphaMin));
}

.purchaseAt {
  margin-top: 6px;
}

.tag {
  margin-top: 6px;
}
