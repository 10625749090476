.icon {
  display: block;
  width: 18px;
  height: 18px;
}

.icon svg {
  display: block;
  width: 100%;
  height: 100%;
}

.icon.inline-block {
  display: inline-block;
  vertical-align: middle;
}

.icon.inline {
  display: inline;
  vertical-align: middle;
}

/* Variant */
.white {
  fill: rgba(var(--clrWhite));
}

.black {
  fill: rgba(var(--clrBlack));
}

.max {
  fill: rgb(var(--clrNtrlMax));
}

.dark {
  fill: rgb(var(--clrNtrlDark));
}

.light {
  fill: rgb(var(--clrNtrlLight));
}

.lighter {
  fill: rgb(var(--clrNtrlLighter));
}

.min {
  fill: rgb(var(--clrNtrlMin));
}

.primary {
  fill: rgba(var(--clrPrimary));
}

.secondary {
  fill: rgba(var(--clrSecondary));
}

.info {
  fill: rgba(var(--clrInfo));
}

.positive {
  fill: rgba(var(--clrPositive));
}

.negative {
  fill: rgba(var(--clrNegative));
}

.warning {
  fill: rgba(var(--clrWarning));
}

.panda {
  fill: rgba(var(--clrPanda));
}

.pandaDark {
  fill: rgba(var(--clrPandaDark));
}

.flamingo {
  fill: rgba(var(--clrFlamingo));
}

.cocodrile {
  fill: rgba(var(--clrCocodrile));
}

.pig {
  fill: rgba(var(--clrPig));
}

.koala {
  fill: rgba(var(--clrKoala));
}

.parrot {
  fill: rgba(var(--clrParrot));
}

.parrotLight {
  fill: rgba(var(--clrParrotLight));
}

.octupus {
  fill: rgba(var(--clrOctopus));
}

.xs {
  width: 12px;
  height: 12px;
}

.s {
  width: 24px;
  height: 24px;
}

.m {
  width: 30px;
  height: 30px;
}

.l {
  width: 50px;
  height: 50px;
}

.xl {
  width: 60px;
  height: 60px;
}
