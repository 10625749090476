.container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  background-color: rgba(var(--clrWhite));
}

.decorator {
  width: 100%;
  background: url('./assets/decorator.svg') no-repeat center bottom / cover;
  margin-bottom: 12px;
}

.decorator.decorator-small {
  height: 64px;
}

.decorator.decorator-large {
  height: 110px;
}

.content {
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content.abs {
  margin-top: 50px;
}
