.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  padding: 0 18px;
  margin-top: -110px;
}

.logo {
  width: 100%;
  flex: 0;
  align-items: center;
}
