.link {
  text-decoration: none;
  display: block;
  transition: opacity 0.3s ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.link:active {
  opacity: 0.6;
}
