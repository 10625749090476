.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.overlayEnter {
  opacity: 1;
}

.overlayExit {
  opacity: 0;
}

.modal {
  background-color: white;
  padding: 1.5rem;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transform: scale(0.9);
  opacity: 0;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.modalEnter {
  transform: scale(1);
  opacity: 1;
}

.modalExit {
  transform: scale(0.9);
  opacity: 0;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
