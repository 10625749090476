.container {
  width: 100%;
  flex-shrink: 0;
  padding: 14px 0;
  outline: none;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.container + .container {
  border-top: solid 1px rgba(var(--clrNtrlMax), var(--alphaMin));
}

.container:first-of-type {
  border-top: solid 1px rgba(var(--clrNtrlMax), var(--alphaMin));
}

.container:last-of-type {
  border-bottom: solid 1px rgba(var(--clrNtrlMax), var(--alphaMin));
}

.avatar {
  width: 48px;
  height: 48px;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 12px;
  flex: 1;
}
