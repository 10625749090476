.calendar :global(.Cal__Weekdays__root .Cal__Weekdays__day) {
  color: #34485e;
  opacity: var(--alpha);
}

.calendar :global(.Cal__Today__root .Cal__Today__chevron) {
  position: initial;
  margin-top: initial;
}

.calendar :global(.Cal__Day__root) {
  font-weight: 500;
}

.calendar :global(.Cal__Day__root.Cal__Day__disabled) {
  font-weight: initial;
}

.calendar :global(.Cal__Day__selection) {
  width: 100%;
  border-radius: 6px;
}

.calendar :global(.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before),
.calendar :global(.Cal__Day__root.Cal__Day__enabled:active:before),
.calendar :global(.Cal__Day__root.Cal__Day__enabled:hover:before) {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  margin-left: 0;
  margin-top: 0;
  transform: translate(-50%, -50%);
}

.calendar :global(.Cal__Day__root.Cal__Day__today:before) {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  margin-left: 0;
  margin-top: 0;
  transform: translate(-50%, -50%);
}

.calendar :global(.Cal__Day__root.Cal__Day__selected .Cal__Day__selection) {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  margin-left: 0;
  margin-top: 0;
  transform: translate(-50%, -50%);
}
