.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 18px 24px;
  box-sizing: border-box;
}

.title {
  margin-bottom: 6px;
}

.content {
  flex: 1;
  overflow: auto;
  box-sizing: border-box;
  margin: 18px -18px;
}

.actions {
  padding-top: 18px;
  flex-shrink: 0;
}

.highlighted {
  color: rgba(var(--clrSecondary));
  font-weight: bold;
}

@media (max-width: 360px) {
  .container {
    padding-top: 48px;
  }
}
