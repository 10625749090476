.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 18px 30px;
  flex: 1;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  align-items: center;
}

.title {
  margin: 30px 0 12px;
}

.illustration {
  max-width: 100%;
}
