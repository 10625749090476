.container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex: 1;
  padding: 0 18px 42px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.header .backButton {
  margin-right: 12px;
}
