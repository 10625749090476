.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 50px 18px 24px 18px;
  overflow: auto;
}

.section + .section {
  margin-top: 30px;
}

.header {
  margin-bottom: 12px;
}

@media screen and (max-width: 320px) {
  .container {
    padding-top: 40px;
  }
}
