.container {
  display: flex;
  align-items: center;
  border-bottom: solid 1px rgba(var(--clrNtrlMax), var(--alphaMin));
  padding: 12px 0;
}

.iconWrapper {
  margin-right: 6px;
}
