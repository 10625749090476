.container {
  position: relative;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  color: rgba(var(--clrNtrlMax));
  font-size: 16px;
  text-align: left;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  height: 20px;
  width: 20px;
  background-color: rgba(var(--clrWhite));
  border: solid 1px rgba(var(--clrNtrlMax));
  margin-right: 6px;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
}

.checkmark::after {
  content: '';
  margin: 3px;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(var(--clrSecondary));
  transform: scale(0);
  transition: transform 0.3s ease;
}

input:checked ~ .checkmark::after {
  transform: scale(1);
}
