.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 18px 42px;
  box-sizing: border-box;
  height: 100%;
}

.content {
  flex: 1;
  overflow: auto;
}
