.container {
  padding: 0 0 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.block {
  max-width: 320px;
  margin: 24px;
}

.title {
  margin-top: 12px;
  margin-bottom: 6px;
}

.cta {
  margin-top: 30px;
}
