.container {
  padding: 80px 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  margin-bottom: 12px;
}

.p {
  margin-top: 12px;
}

.contactWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 30px;
}

.logo {
  max-width: 320px;
}

.contactData {
  margin-left: 24px;
}

@media (max-width: 768px) {
}

@media (max-width: 500px) {
  .logo {
    max-width: 80%;
  }

  .contactData {
    margin-top: 24px;
    margin-left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .contactData p {
    text-align: center;
  }
}
