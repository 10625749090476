.tag {
  padding: 1px 6px;
  border-radius: 3px;
  width: fit-content;
}

.ntrl {
  background-color: rgba(var(--clrNtrlMax));
}

.secondary {
  background-color: rgba(var(--clrSecondary));
}

.negative {
  background-color: rgba(var(--clrNegative));
}
