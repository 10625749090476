.container {
  width: 100%;
  background: linear-gradient(180deg, rgba(var(--clrWhite)) 0%, #e6e6e6 100%) fixed;
}

.p {
  margin-bottom: 6px;
}

.header {
  margin-top: 24px;
}

.backgroundDecorator {
  padding-top: 480px;
}

.mainSection {
  display: flex;
  position: relative;
}

.welcomeContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}

.welcomeWrapper {
  flex: 1;
  background: url(./assets/decorator.svg) no-repeat top center;
  padding: 120px 54px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  flex-shrink: 0;
  max-height: 600px;
}

.intro {
  padding: 0 48px 80px;
}

.logo {
  margin-bottom: 30px;
}

.title {
  font-size: 30px;
  margin-bottom: 12px;
}

.cta {
  margin-top: 30px;
  width: 220px;
}

.image {
  flex: 1;
  object-fit: cover;
  max-width: 50%;
  max-height: 684px;
  flex-shrink: 0;
}

@media (min-width: 1440px) {
  .backgroundDecorator {
    opacity: 0;
    padding-top: 100px;
  }

  .image {
    max-height: initial;
  }

  .intro {
    margin-top: 80px;
  }
}

@media (max-width: 768px) {
  .image {
    display: none;
  }

  .welcomeWrapper {
    padding: 60px 24px;
    background: none;
  }

  .container {
    padding-top: 120px;
    background: url(./assets/decorator.svg) no-repeat top center,
      linear-gradient(180deg, rgba(var(--clrWhite)) 0%, #e6e6e6 100%) fixed;
  }

  .intro {
    padding: 0 24px 40px;
  }
}
