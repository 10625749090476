.container {
  padding: 6px;
  box-sizing: border-box;
  position: relative;
  min-height: 72px;
  display: flex;
  align-items: center;
}

.wrapper {
  flex: 1;
  max-width: calc(100% - 60px);
}

.content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1;
}

.content:first-of-type {
  margin-bottom: 6px;
}

.label {
  letter-spacing: 1.6px;
  text-transform: uppercase;
  opacity: 0.4;
  margin-right: 6px;
}

.iconWrapper {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.username {
  width: 100%;
}
