.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 50px 18px 24px 18px;
  overflow: auto;
}

.message {
  margin-bottom: 12px;
}
