.wrapper {
  display: flex;
  flex: 1;
}

.transitionable {
  position: relative;
  display: flex;
  overflow: visible;
  -webkit-transition: transform 0.2s;
  transition: transform 0.2s;
}

.carousel__count {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 8px;
  color: white;
  font-size: 26px;
}
