.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

:global(.Toastify) .toasts {
  position: absolute;
}

@media (max-width: 500px) {
  .container {
    padding: 0;
  }

  :global(.Toastify) .toasts {
    left: 12px;
    right: 12px;
    top: 12px;
    box-sizing: border-box;
    width: auto;
  }
}

.content {
  width: 438px;
  height: 100%;
  max-height: 725px;
  position: relative;
  background-color: #000;
  background: url('./assets/device_light.svg') no-repeat center center / 370px 725px;
}

.appWrapper {
  top: 68px;
  left: 48px;
  right: 48px;
  bottom: 52px;
  border-radius: 4px;
  position: absolute;
  overflow: hidden;
  background-color: #000;
}

@media (max-width: 500px) {
  .content {
    background: none;
    width: 100%;
    height: 100%;
    max-height: initial;
  }

  .appWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    border-radius: 0;
  }
}
