.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  box-sizing: border-box;
}

.title {
  margin-bottom: 18px;
}
