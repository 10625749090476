.container {
  padding: 6px;
  box-sizing: border-box;
  position: relative;
  height: 72px;
}

.content {
  display: flex;
  align-items: center;
  height: 100%;
}

.unlockText {
  padding-left: 78px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  opacity: 0.3;
}

.successText {
  letter-spacing: 1.6px;
  text-transform: uppercase;
  opacity: 0.3;
}

.latch {
  position: absolute;
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  box-sizing: border-box;
  background-color: transparent;
  margin: 0;
  width: calc(100% - 12px);
  transition: all 0.3s;
  height: 60px;
}

.latch::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 6px;
  background: url('./assets/iconLock.svg') center / 24px 36px no-repeat rgba(var(--clrPrimary));
  z-index: 1;
  width: 60px;
  position: relative;
  height: 60px;
  box-shadow: 0 1px 5px rgba(var(--clrBlack), var(--alpha));
}

.latch::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 6px;
  background: url('./assets/iconLock.svg') center / 24px 36px no-repeat rgba(var(--clrPrimary));
  z-index: 1;
  width: 60px;
  position: relative;
  height: 60px;
  box-shadow: 0 1px 5px rgba(var(--clrBlack), var(--alpha));
}

.latch::-ms-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 6px;
  background: url('./assets/iconLock.svg') center / 24px 36px no-repeat rgba(var(--clrPrimary));
  z-index: 1;
  width: 60px;
  position: relative;
  height: 60px;
  box-shadow: 0 1px 5px rgba(var(--clrBlack), var(--alpha));
}

.container.working .latch {
  opacity: 0;
}

.container.working .content {
  justify-content: center;
}

.errorWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  padding: 6px 12px;
  z-index: 10;
  align-items: center;
  display: none;
}

.errorWrapper.shown {
  display: flex;
  animation: fadeIn 0.3s ease 0s 1 normal forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
