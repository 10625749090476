.wrapper {
  width: 100%;
  position: relative;
}

.input {
  width: 100%;
  height: 36px;
  background-color: rgba(var(--clrWhite));
  border: none;
  padding: 0 12px;
  color: rgba(var(--clrNtrlMax));
  font-size: 14px;
  outline: none;
  border: solid 1px rgba(var(--clrNtrlMax), 0.3);
  box-sizing: border-box;
  transition: all 0.3s ease;
  box-shadow: 0 0 3px rgba(var(--clrBlack), var(--alphaMin));
}

.input[disabled] {
  background-color: rgba(var(--clrNtrlLighter));
}

.input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(var(--clrWhite));
  opacity: 1; /* Firefox */
}

.input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(var(--clrWhite));
}

.input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(var(--clrWhite));
}

.placeholder {
  margin-bottom: 6px;
}

.error {
  color: rgba(var(--clrNegative));
  border-color: rgba(var(--clrNegative));
}

.inputWithIcon {
  padding-right: 42px;
}

.iconWrapper {
  position: absolute;
  bottom: 0;
  right: 12px;
  display: flex;
  align-items: center;
  height: 36px;
}

/* Variants */
.input.light {
  background-color: rgba(var(--clrNtrlMin));
  color: rgba(var(--clrNtrlMax));
  border-color: rgba(var(--clrNtrlMax), 0.3);
}

.input.light::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(var(--clrNtrlMax));
  opacity: 1; /* Firefox */
}

.input.light:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(var(--clrNtrlMax));
}

.input.light::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(var(--clrNtrlMax));
}

@media (max-width: 500px) {
  .input {
    font-size: 16px;
  }
}
