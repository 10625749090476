.container {
  padding: 80px 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  margin-bottom: 12px;
}

.cta {
  margin-top: 30px;
  width: 220px;
}
