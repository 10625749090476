.container {
  display: flex;
  justify-content: space-between;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  margin-right: 12px;
}

.action {
  width: auto;
  height: 32px;
  padding: 0 12px;
}
