.container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  background-color: rgba(var(--clrCocodrile));
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 18px 24px 18px;
}

.container .avatar {
  width: 48px;
  height: 48px;
  margin-right: 6px;
}

.wrapper {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.wrapperContent {
  display: flex;
  flex-direction: column;
  background: url('./assets/decorator.svg') center bottom no-repeat, rgba(var(--clrWhite));
  flex: 1;
  padding-top: 0px;
  padding-bottom: 120px;
}

.unlockWrapper {
  margin: 6px 18px;
}

.wifiWrapper {
  margin: 24px 18px;
}

.notification {
  margin: 24px 18px;
}

/**/
.contentWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: url('./assets/decorator.svg') center bottom no-repeat, rgba(var(--clrWhite));
}

.carouselContent {
  flex: 1;
  padding: 24px 0 0 18px;
}

.carouselInfo {
  padding-right: 18px;
}

.title {
  margin-bottom: 6px;
}

.p {
  margin-bottom: 18px;
}
