.container {
  width: 50px;
  height: 24px;
  display: flex;
  align-items: center;
  padding: 2px;
  background-color: rgba(var(--clrNtrlMax), 0.3);
  border-radius: 12px;
  box-sizing: border-box;
  position: relative;
  transition: all 0.3s ease;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container.on .switch {
  left: 28px;
  background-color: rgba(var(--clrWhite));
}

.container.on {
  background-color: rgba(var(--clrSecondary));
}

.switch {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: rgba(var(--clrNtrlMax));
  position: absolute;
  left: 2px;
  top: 2px;
  transition: all 0.3s ease;
}
