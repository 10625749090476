.container {
  margin: 0 24px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.when {
  margin-bottom: 6px;
}
