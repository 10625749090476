.container {
  padding: 80px 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  box-sizing: border-box;
}

.timetables {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.timeDetails {
  width: 33%;
  max-width: 320px;
  margin: 24px;
  padding-bottom: 30px;
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px 0 30px;
  border-bottom: solid 1px rgba(var(--clrNtrlMin));
  margin-bottom: 30px;
}

.icon {
  margin-right: 6px;
}

.title {
  opacity: 0.8;
  letter-spacing: 1.2px;
}

.row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  padding: 12px 12px;
}

.row + .row {
  margin-top: 12px;
  border-top: solid 1px rgba(var(--clrNtrlMin));
}

.block {
  margin-top: 24px;
}

.p {
  margin-bottom: 24px;
}

.title {
  margin-top: 12px;
  margin-bottom: 6px;
}

@media (max-width: 768px) {
  .timeDetails {
    width: 40%;
  }
}

@media (max-width: 500px) {
  .timeDetails {
    width: 100%;
  }
}

.button {
  margin-top: 48px;
  position: relative;
  padding: 0 24px;
  height: 48px;
  border-radius: 24px;
  background-color: transparent;
  border: solid 1px transparent;
  font-size: 16px;
  color: rgba(var(--clrWhite));
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
  /* fix a strange behavior with transparency hover that increase the grid height */
  transform: translateZ(0);
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 16px;
  border-color: rgb(var(--clrNtrlMax));
  color: rgb(var(--clrNtrlMax));
  display: inline-flex;
  text-decoration: none;
  box-sizing: border-box;
}

.button:active,
.button:hover {
  opacity: 0.6;
}

@media (max-width: 768px) {
  .container {
    padding: 80px 24px;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .timeDetails {
    margin: 24px 0;
  }
}
