.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 0 18px 42px;
}

.description {
  margin-top: 24px;
  margin-bottom: 18px;
}
