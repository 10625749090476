.container {
  margin: 0 18px;
  padding: 12px 0;
  border-bottom: solid 1px rgba(var(--clrNtrlLight));
}

.container:last-of-type {
  border-bottom: 0;
}

.label {
  margin-bottom: 6px;
}
