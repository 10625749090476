.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  flex: 1;
  padding: 0 18px 42px;
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.action + .action {
  margin-top: 12px;
}
