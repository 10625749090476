.container {
  width: 438px;
  height: 100%;
  max-height: 725px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 256;
}

.wrapper {
  top: 68px;
  left: 48px;
  right: 48px;
  bottom: 52px;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: rgba(var(--clrWhite));
  z-index: 256;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 18px;
}

.content {
  flex: 1;
  overflow: auto;
}

:global(.slideUp-enter) {
  opacity: 0;
  transform: translateY(50%) translateX(-50%);
}

:global(.slideUp-enter-active) {
  opacity: 1;
  transform: translateY(-50%) translateX(-50%);
  transition: opacity 0.3s, transform 0.3s;
}

:global(.slideUp-exit) {
  opacity: 1;
}

:global(.slideUp-exit-active) {
  opacity: 0;
  transform: translateY(50%) translateX(-50%);
  transition: opacity 0.6s, transform 0.3s;
}

@media (max-width: 500px) {
  .container {
    width: 100%;
    height: 100%;
    max-height: initial;
  }

  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
  }
}
