.container {
  height: 100%;
  padding: 0 18px 24px;
  box-sizing: border-box;
  overflow: auto;
}

.rowHeader {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 6px;
}

.red {
  fill: rgba(var(--clrPig));
}

.yellow {
  fill: rgba(var(--clrPanda));
}

.blue {
  fill: rgba(var(--clrCocodrile));
}

.disabled {
  fill: rgba(var(--clrNtrlLight));
  opacity: var(--alpha);
}

.primary {
  fill: rgba(var(--clrPrimary));
}

.list {
  margin: 12px 0;
  padding-left: 0;
  list-style: none;
  list-style-type: none;
}

.list li + li {
  margin-top: 12px;
}

.block + .block {
  margin-top: 36px;
}
