:root {
  /* Corporative colors */
  --clrPrimary: 26, 145, 215;
  --clrPrimaryLt: 134, 190, 253;

  --clrSecondary: 149, 27, 129;
  --clrSecondaryLt: 195, 161, 240;

  /* Neutrals */
  --clrNtrlMax: 69, 80, 89;
  --clrNtrlDark: 42, 78, 106;
  --clrNtrlLight: 79, 109, 133;
  --clrNtrlLighter: 132, 146, 159;
  --clrNtrlMin: 239, 239, 239;

  /* auxiliary */
  --clrBlack: 0, 0, 0;
  --clrWhite: 255, 255, 255;

  /* Semantics */
  --clrNegative: 255, 78, 87;
  --clrNegativeLt: 255, 158, 163;
  --clrPositive: 119, 223, 88;
  --clrPositiveLt: 173, 242, 153;
  --clrWarning: 251, 150, 35;
  --clrWarningLt: 255, 204, 145;
  --clrInfo: 35, 136, 251;
  --clrInfoLt: 134, 190, 253;

  /* other colors */
  --clrPanda: 254, 197, 50;
  --clrFlamingo: 24, 104, 62;
  --clrCocodrile: 22, 77, 160;
  --clrPig: 191, 0, 43;
  --clrKoala: 110, 89, 58;
  --clrParrot: 94, 154, 50;
  --clrParrotLight: 142, 184, 111;

  /* Alpha */
  --alphaMin: 0.1;
  --alpha: 0.5;
  --alphaMax: 0.8;

  /* Font */
  --fontLetterSpacing: 2px;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

* {
  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: rgba(var(--clrPrimary));
}
