.container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex: 1;
  padding: 0 18px 42px;
  height: 100%;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 42px;
  padding-top: 12px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.header .backButton {
  margin-right: 12px;
}

.chartWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content .chart {
  margin-bottom: 24px;
  margin-top: 24px;
  width: 100px;
}

.actions {
  margin-top: 30px;
  flex: 1;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}

.actionsTitle {
  margin-bottom: 12px;
}

.row + .row {
  border-top: solid 1px rgba(var(--clrNtrlMax), var(--alphaMin));
}
