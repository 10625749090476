.container {
  overflow: hidden;
  display: flex;
  width: 100%;
  height: 100%;
}

.infoWrapper {
  flex: 1;
  margin-right: 12px;
}

.decorator {
  height: 100%;
  flex: 1;
  position: relative;
}

.decorator:before {
  content: '';
  display: block;
  position: absolute;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  top: -30px;
}

.decorator.seat:before {
  background-color: rgba(var(--clrFlamingo));
}

.decorator.place:before {
  background-color: rgba(var(--clrParrot));
}

.decorator.meetingsRoom:before {
  background-color: rgba(var(--clrPig));
}

.decoratorContent {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tag {
  margin-top: 6px;
  display: block;
}
