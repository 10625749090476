.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: 50px 18px 24px 18px;
}

.actions {
  padding: 24px 18px;
  height: 96px;
  box-sizing: border-box;
}

.form {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 320px) {
  .container {
    padding-top: 40px;
  }
}
