.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 0;
  gap: 12px;
}

.content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.container + .container {
  border-top: solid 1px rgba(var(--clrNtrlMin));
}
