.container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex: 1;
  padding: 0 18px 42px;
  overflow: auto;
}

.description {
  margin-top: 6px;
}

.extraInfo {
  margin-top: 12px;
  font-style: italic;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.header .backButton {
  margin-right: 12px;
}

.tags {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.tag {
  margin-top: 6px;
}

.tag + .tag {
  margin-left: 6px;
}

.options {
  margin-top: 24px;
}

.row {
  padding: 12px 0;
}

.row + .row {
  border-top: solid 1px rgba(var(--clrNtrlMax), var(--alphaMin));
}

.row:first-of-type {
  border-top: solid 1px rgba(var(--clrNtrlMax), var(--alphaMin));
}

.row:last-of-type {
  border-bottom: solid 1px rgba(var(--clrNtrlMax), var(--alphaMin));
}

.autoRenew {
  padding: 0;
}

.autoRenewHelpText {
  margin-top: 12px;
}

.price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invoicesLink {
  padding: 0;
}

.actions {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
}

.action + .action {
  margin-top: 12px;
}
