.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  position: absolute;
  top: -20px;
  width: 100%;
  box-sizing: border-box;
}

.actionWrapper {
  flex: 1;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin: 0 6px;
  padding: 6px;
  gap: 12px;
}

.actionWrapper.seat {
  background-color: rgba(var(--clrParrot));
}

.actionWrapper.place {
  background-color: rgba(var(--clrParrot));
}

.actionWrapper.meetingsRoom {
  background-color: rgba(var(--clrPig));
}

.completeProfileContainer {
  display: flex;
  justify-content: space-between;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.action {
  width: auto;
  height: 32px;
  padding: 0 12px;
  margin-top: 12px;
  align-self: flex-end;
}
