.container {
  width: 100%;
}

.container img {
  aspect-ratio: 16/9;
  width: 100%;
  object-fit: cover;
}

.caption {
  margin: 12px 0;
}
