.container {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: solid 1px rgba(var(--clrNtrlDark), var(--alpha));
  position: relative;
}

.icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: rgba(var(--clrNtrlMin));
  border-radius: 50%;
}

.floatIcon {
  width: 42px;
  height: 42px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  background-color: rgba(var(--clrSecondary));
}

.avatar {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 320px) {
  .container {
    width: 100px;
    height: 100px;
  }

  .floatIcon {
    width: 40px;
    height: 40px;
  }
}
