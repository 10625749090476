.container {
  width: 100%;
  min-height: 76px;
}

.radio {
  font-weight: 600;
}

.description {
  margin-top: 6px;
  padding-left: 28px;
}

.extraInfo {
  margin-top: 6px;
  padding-left: 28px;
  font-style: italic;
}

.priceWrapper {
  padding-left: 28px;
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.price {
  display: flex;
  align-items: center;
  position: relative;
}

.discount {
  margin-left: 12px;
  display: flex;
  align-items: center;
}

.price.disabled {
  opacity: var(--alpha);
}

.price.disabled:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%) rotateZ(-12deg);
  height: 2px;
  background: rgba(var(--clrNtrlMax));
}

.tag {
  background-color: rgba(var(--clrNegative));
  padding: 1px 6px;
  border-radius: 3px;
  margin-left: 6px;
}
